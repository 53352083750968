import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Content from "../content.json";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import SectionWrapper from "../components/sectionWrapper";
import OneColWrapper from "../components/oneColWrapper";
import TwoColWrapper from "../components/twoColWrapper";
import ImageWithCaption from "../components/imageWithCaption";
import Image from "../components/image";
import Text from "../components/text";
import Caption from "../components/caption";
export const _frontmatter = {
  "title": "aiiot",
  "author": "aiiot"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Home" keywords={["aiiot", "ambient intelligent spaces", "privacy"]} mdxType="SEO" />
    <Layout mdxType="Layout">
  <Header bgcolor="#AAD0AA" mdxType="Header">
      <Text mdxType="Text">
        <p className="subheadline">{Content.header.subline}</p>
        <h1>{Content.header.headline}</h1>
        <p dangerouslySetInnerHTML={{
            __html: Content.header.text
          }}></p>
      </Text>
      <Image name={Content.header.image} type="png" mdxType="Image" />
  </Header>
  {
        /* CloudyTimes */
      }
  <SectionWrapper mdxType="SectionWrapper">
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>{Content.cloudySection.headline01}</h1>
        <p dangerouslySetInnerHTML={{
              __html: Content.cloudySection.text01
            }}></p>
      </Text>
    </OneColWrapper>
    <OneColWrapper mdxType="OneColWrapper">
      <Image name={Content.cloudySection.image} mdxType="Image" />
      <Caption mdxType="Caption">Many intelligent products today send data to the cloud and are dependent on it.</Caption>
    </OneColWrapper>
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h2>{Content.cloudySection.headline02}</h2>
        <p>{Content.cloudySection.text02}</p>
      </Text>
    </OneColWrapper>
  </SectionWrapper>
  {
        /* Mates Who Dis? */
      }
  <SectionWrapper bgcolor="#F6F6F6" mdxType="SectionWrapper">
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>{Content.matesWhoDis.headline01}</h1>
        <p>{Content.matesWhoDis.text01}</p>
      </Text>
      <Image name={Content.matesWhoDis.image} type="jpg" mdxType="Image" />
      <Caption mdxType="Caption">The different layers of a Mate. Having everything on-board, makes a connection to a cloud unneccessary.</Caption>
    </OneColWrapper>
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h3>{Content.matesWhoDis.headline02}</h3>
        <p>{Content.matesWhoDis.text02}</p>
      </Text>
      <Text mdxType="Text">
        <h3>{Content.matesWhoDis.headline03}</h3>
        <p>{Content.matesWhoDis.text03}</p>
      </Text>
    </TwoColWrapper>
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h3>{Content.matesWhoDis.headline04}</h3>
        <p>{Content.matesWhoDis.text04}</p>
      </Text>
      <Text mdxType="Text">
        <h3>{Content.matesWhoDis.headline05}</h3>
        <p>{Content.matesWhoDis.text05}</p>
      </Text>
    </TwoColWrapper>
  </SectionWrapper>
  {
        /* Mates */
      }
  <SectionWrapper mdxType="SectionWrapper">
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h2>{Content.mates.headline01}</h2>
        <p>{Content.mates.text01}</p>
      </Text>
      <Image name={Content.mates.image01} type="jpg" mdxType="Image" />      
    </TwoColWrapper>
    
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h2>{Content.mates.headline02}</h2>
        <p>{Content.mates.text02}</p>
      </Text>
      <Image name={Content.mates.image02} type="jpg" mdxType="Image" />      
    </TwoColWrapper>
    
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h2>{Content.mates.headline03}</h2>
        <p>{Content.mates.text03}</p>
      </Text>
      <Image name={Content.mates.image03} type="jpg" mdxType="Image" />      
    </TwoColWrapper>
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h2>{Content.mates.headline04}</h2>
        <p>{Content.mates.text04}</p>
      </Text>
      <Image name={Content.mates.image04} type="jpg" mdxType="Image" />      
    </TwoColWrapper>
    <OneColWrapper mdxType="OneColWrapper">
      <Image name={Content.matesWhoDis.image02} type="jpg" mdxType="Image" />
      <Caption mdxType="Caption">The system behind Aiiot: Real-World data gets connected to IoT Devices with your own flows.</Caption>
    </OneColWrapper>
  </SectionWrapper>
  {
        /* Homestation */
      }
  <SectionWrapper bgcolor="rgb(240, 244, 245)" mdxType="SectionWrapper">
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>{Content.homestation.headline01}</h1>
        <p>{Content.homestation.text01}</p>
      </Text>
    </OneColWrapper>
    <OneColWrapper mode="fullWidth" mdxType="OneColWrapper">
      <Image name={Content.homestation.image} type="jpg" mdxType="Image" />      
    </OneColWrapper>
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>{Content.homestation.headline02}</h1>
        <p>{Content.homestation.text02}</p>
      </Text>
    </OneColWrapper>
  </SectionWrapper>
      {
        /* Status Quo */
      }
  <SectionWrapper mdxType="SectionWrapper">    
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>{Content.statusquo.headline01}</h1>
        <p>{Content.statusquo.text01}</p>
      </Text>
    </OneColWrapper>
    <OneColWrapper mdxType="OneColWrapper">
      <Image name={Content.statusquo.image} type="jpg" mdxType="Image" />
      <Caption mdxType="Caption">Machine Learning can already be used on very basic hardware.</Caption>
    </OneColWrapper>
    <TwoColWrapper mdxType="TwoColWrapper">
        <Text mdxType="Text">
          <h2>{Content.statusquo.headline02}</h2>
          <p>{Content.statusquo.text02}</p>
        </Text>
        <Text mdxType="Text">
          <h2>{Content.statusquo.headline03}</h2>
          <p dangerouslySetInnerHTML={{
              __html: Content.statusquo.text03
            }}></p>
        </Text>
    </TwoColWrapper>
  </SectionWrapper>
      {
        /* Journey */
      }
  <SectionWrapper mdxType="SectionWrapper">
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <hr />
        <h1>{Content.roadmap.headline01}</h1>
        <p>{Content.roadmap.text01}</p>
      </Text>
    </OneColWrapper>
    <OneColWrapper mode="fullWidth" mdxType="OneColWrapper">
      <Image name={Content.roadmap.image} type="svg" mdxType="Image" />  
    </OneColWrapper>
    <TwoColWrapper mdxType="TwoColWrapper">      
      <Text mdxType="Text">
        <h2>{Content.roadmap.headline02}</h2>
        <p>{Content.roadmap.text02}</p>
      </Text>
      <Text mdxType="Text">
        <h2>{Content.roadmap.headline03}</h2>
        <p>{Content.roadmap.text03}</p>
      </Text>
    </TwoColWrapper>
  </SectionWrapper>
      {
        /* Vision */
      }
  <SectionWrapper mdxType="SectionWrapper">
    <OneColWrapper mdxType="OneColWrapper">      
      <Text mdxType="Text">
        <h1>{Content.vision.headline01}</h1>
        <p>{Content.vision.text01}</p>      
      </Text>      
    </OneColWrapper>
    <OneColWrapper mode="fullWidth" mdxType="OneColWrapper">
      <Image name={Content.vision.image} type="jpg" mdxType="Image" />  
    </OneColWrapper>
  </SectionWrapper>
      {
        /* About */
      }
  <SectionWrapper mdxType="SectionWrapper">
    <TwoColWrapper mdxType="TwoColWrapper">
      <Text mdxType="Text">
        <h1>{Content.about.headline01}</h1>
        <p dangerouslySetInnerHTML={{
              __html: Content.about.text01
            }}></p>
      </Text>
      <Image name={Content.about.image} type="svg" mdxType="Image" />  
    </TwoColWrapper>
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>{Content.about.headline02}</h1>
        <p dangerouslySetInnerHTML={{
              __html: Content.about.text02
            }}></p>
      </Text>
    </OneColWrapper>    
  </SectionWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      